import React from "react"
import { Link } from "gatsby"

import Layout from "../layout"
import Seo from "../components/Seo"
import styled from "styled-components"

const Paragraph = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
  margin-top: 5vh;
  height: 85vh;
`
const SLink = styled(Link)`
  text-decoration: none;
  color: black;
  text-transform: to-uppercase;
  font-size: 1.5em;
  :hover {
    border-bottom: 1px solid grey;
  }
`

const NotFoundPage = () => (
  <Layout>
    <Paragraph>
      <Seo title="404: Not found" />
      <h1>Page Not Found</h1>
      <p>We don&#39;t have a page like that here. A shame, really.</p>
      <p>
        However, you can go back <SLink to="/">Home</SLink> to find out more
        about me.
      </p>
    </Paragraph>
  </Layout>
)

export default NotFoundPage
